import axios from "axios";
import { setRichieste } from "./localbase";
//const TOKEN = "Bearer 51b21f71f8df77f12f03481bab7655fd";

export default {
  /**
   *
   * @param userID id of the logges user
   * @returns array of richieste
   *
   * Get last 50 richieste for the logged user
   */
  async getRichieste(dipendenteID: string) {
    const data = new FormData();
    data.append("limit", "50");
    data.append("where[richieste_user_id]", dipendenteID.toString());
    data.append("orderby", "richieste_creation_date");
    data.append("orderdir", "desc");

    const response = await axios.post("rest/v1/search/richieste", data, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    localStorage.setItem("richieste", JSON.stringify(response.data.data));
    // Save into indexeddb => dbRichieste
    // con chiave id documento_dipendente e valore il documento
    response.data.data.forEach((richiesta) => {
      setRichieste(richiesta);
    });
    //console.log(response);
    return response.data.data;
  },

  /**
   *
   * @param id id of richiesta
   * @returns richiesta object
   *
   * Get richiesta detail
   */
  async getRichiestaDetail(id: string) {
    const data = new FormData();
    data.append("where[richieste_id]", id.toString());

    const response = await axios.post("rest/v1/search/richieste", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data.data[0];
  },

  /**
   *
   * @returns tipologie richiesta array
   *
   * Get richiesta tipologia
   */
  async getTipologiaRichiesta() {
    const response = await axios.get(`rest/v1/search/richieste_tipologia`, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data.data;
  },

  /**
   *
   * @param richiesta array with richiesta data
   * @returns richiesta just created
   *
   * Save richiesta
   */
  async saveRichiesta(richiesta) {
    const response = await axios.post(`rest/v1/create/richieste`, richiesta, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },
};
